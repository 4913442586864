import React from "react"
import {Container, Row, Col} from 'react-bootstrap'
import Layout from "../../components/Layout/Layout"
import HeroBanner from "../../components/HeroBanner/HeroBanner"
import { Link } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import "./blog.css"

const BlogPage = () => {
    return (
  <Layout
    title="SEO, Digital Marketing, and Web Development Blog | Guiding Digital"
    description="Articles from Guiding Digital on SEO, online presence, social marketing, and web development topics.">
    <HeroBanner
      headlineLine1="Guiding Digital"
      headline="Blog"
      description="From new technologies, digital strategies, and case studies, we like to share our thoughts."
      className="blogPage"
    />

    <Container>
      <Row className="m-3 pb-5 blog-item">
          <Col xs={12} md={6} className="d-none d-md-block">
            <Link to="/blog/core-web-vitals/">
              <StaticImage
                src="../../images/blog/core-web-vitals.png"
                alt="Core Web Vitals"
                layout="fullWidth" />
            </Link>
          </Col>
          <Col xs={12} md={6}>
              <h2><Link to="/blog/core-web-vitals/">Google Core Web Vitals Update</Link></h2>
              <div className="pb-4">
                  May 13, 2021<br /><br />
                  Google is releasing their page experience update to their 
                  search engine ranking system which includes core 
                  web vitals metrics.  Learn more about core web 
                  vitals and how you can prepare for the update.
              </div>
              <Link to="/blog/core-web-vitals/">View Article &gt;</Link>
          </Col>
        </Row>
        <Row className="m-3 pb-5 blog-item">
            <Col xs={12} md={6} className="d-none d-md-block">
              <Link to="/blog/small-business-online-presence-tips/">
                <StaticImage
                  src="../../images/blog/online-presence.png"
                  alt="5 Tips For Improving Your Online Presence"
                  layout="fullWidth" />
              </Link>
            </Col>
            <Col xs={12} md={6}>
                <h2><Link to="/blog/small-business-online-presence-tips/">5 Tips For Improving Your Online Presence</Link></h2>
                <div className="pb-4">
                    June 18, 2020<br /><br />
                    COVID-19 has reminded us on how imporant it is to have a solid online presence. In this 
                article I give 5 tips on improving your online presence.
                </div>
                <Link to="/blog/small-business-online-presence-tips/">View Article &gt;</Link>
            </Col>
        </Row>
              

        
    </Container>

  </Layout>
    )
}

export default BlogPage